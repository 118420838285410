.tokenImg {
    max-width: 150px;
    max-height: 150px;
    width: auto;
    height: auto;
}

.token {
    cursor: pointer;
}

.token img {
    animation: spin 1.0s ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .token:not(:hover) img {
    animation-name: none;
  }

.token:hover img {
    animation-play-state: running;
}


@keyframes spin {
    from {
        transform: scaleX(1);
    }
    to {
        transform: scaleX(-1);
    }
}

/**
Dynamically change displays for different sizes
*/
@media (max-width: 500px) {  
    .token {
        width: 50%;
    }
}

@media (min-width: 500px) and (max-width: 1100px) {  
    .token {
        width: 33.3333%;
    }
    
}
@media (max-width: 1100px) {  
    .token-container {
        flex-wrap: wrap;
        flex-direction: row;
        display: flex
    }
    .token {
        flex: none;
    }
}
@media (min-width: 1100px) {  
    .token {
        flex: 1;
    }
    .token-container {
        flex-direction: row;
        display: flex
    }
}

