.background {
    background-image: url('./images/homePage_bg.png');
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    -ms-content-zooming: none;
    position:absolute;
    width:100%;
    height:100%;
    overflow: scroll;
    display: block;
    background-position: center;
}

.gith-images {
    max-width: 200px;
    max-height: 200px;
    width: auto;
    height: auto;
    border-radius: 10px;
}

.collapse-header {
    display: flex;
    justify-content: center;
}

.content {
    backdrop-filter: blur(5px);
    overflow: scroll;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
}

html, body, #root {
    height: 100%;
}

.letter {
    width: 75%;
    margin-top: 20px;
}


@media (max-width: 900px) {  
    .letter {
        width: 100%;
    }
}