.background2 {
    background-image: url('./images/landingPage_bg.png');
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    -ms-content-zooming: none;
    position:absolute;
    width:100%;
    height:100%;
    overflow: scroll;
    background-position: center;
}

.background-falling-star-garden {
    background-image: url('./images/feywild/falling star garden.jpeg');
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    -ms-content-zooming: none;
    position:absolute;
    width:100%;
    height:100%;
    overflow: scroll;
    background-position: center;
}

.content {
    backdrop-filter: blur(5px);
    overflow: scroll;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
}

html, body, #root {
    height: 100%;
}

@keyframes jump {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2px);
    }
}

