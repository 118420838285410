.auth-box {
    background-color: var(--main-white);
    align-self: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 75%;
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.my-blurred-div {
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2px);
}

.text-input {
    width: 50%;
    align-self: center;
}

.inputs-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.buttons-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    align-self: center;
}

@media (max-width: 615px) {  
    .bottom-text-block {
        display: block;
    }
}
