.paper-styling {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.clickable {
    cursor: pointer;
}

.collapse-tab {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: left;
}

.flex-el {
    flex: 1;
}

.card-name {
    text-align: center;
}

.imgStyling {
    max-width: 150px;
    max-height: 80px;
    width: auto;
    height: auto;
}

/**
Dynamically change displays for different sizes
*/
@media (max-width: 500px) {  
    .card {
        width: 50%;
    }
}

@media (min-width: 500px) and (max-width: 1100px) {  
    .card {
        width: 33.3333%;
    }
    
}
@media (max-width: 1100px) {  
    .title-selector {
        flex-wrap: wrap;
        flex-direction: row;
        display: flex
    }
    .card {
        flex: none;
    }
}
@media (min-width: 1100px) {  
    .card {
        flex: 1;
    }
    .title-selector {
        flex-direction: row;
        display: flex
    }
}


