.auth-background {
    background-image: url('./login_bg.jpeg');
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.filler-box {
    flex: 1
}

.auth-box {
    background-color: var(--main-white);
    align-self: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 75%;
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
}


.bottom-text-block {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 50%;
    align-self: center;
    align-items: center;
    justify-content: right;
}